import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

import { InnerHero, Layout } from "../components"
import Seo from "../components/seo"
import CallToAction from "../components/CallToAction"

const query = graphql`
  {
    imageOne: allPrismicImages(
      filter: { data: { image: { alt: { eq: "Is Your Website Slow?" } } } }
    ) {
      nodes {
        data {
          image {
            alt
            fixed(width: 300, height: 300) {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
        }
      }
    }

    imageTwo: allPrismicImages(
      filter: { data: { image: { alt: { eq: "hand shake" } } } }
    ) {
      nodes {
        data {
          image {
            alt
            fixed(width: 300, height: 300) {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
        }
      }
    }

    services: allPrismicServices(sort: { order: ASC, fields: id }) {
      nodes {
        data {
          info {
            text
          }
          name {
            text
          }
          extra {
            text
          }
          tags {
            tag {
              text
            }
          }
          email {
            text
          }
        }
        id
      }
    }
  }
`

const ServicesPage = ({ page }) => {
  const {
    imageOne: { nodes: imageOne },
    imageTwo: { nodes: imageTwo },
    services: { nodes: services },
  } = useStaticQuery(query)

  // const images = marketings

  const imageOneAlt = imageOne[0].data.image.alt
  const imageTwoAlt = imageTwo[0].data.image.alt

  const fixedOne = imageOne[0].data.image.fixed
  const fixedTwo = imageTwo[0].data.image.fixed

  return (
    <Layout>
      <Seo
        title="Web design & web development services near me - Vermont Web Development"
        description="I am a web designer and developer based in Vermont. Improve your website performance with fast, secure, and SEO-friendly websites, and you'll rank higher for crucial keywords. I am building a website that will help increase your sales."
      />
      <main>
        <section id="services-page">
          <div className="section u-padding-bottom">
            {!page && (
              <>
                <InnerHero>
                  <h1 className="heading__main-title heading__main-title--black">
                    Services
                  </h1>
                  <h3 className="heading__main-title--sub u-no-effect">
                    Let me increase your sale by providing custom website design
                    and development services that create a more professional
                    presence at an affordable cost.
                  </h3>
                </InnerHero>
              </>
            )}
          </div>

          <section className="section">
            <div className="u-column-wrap u-left-text u-padding">
              <div className="row">
                <div className="col-2-of-2 u-no-margin">
                  <article className="services-page__article">
                    <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                      Is Your Website Slow?
                    </h2>
                    <p className="paragraph">
                      No one want to wait. If your website takes more than 2
                      seconds to load, your customers will leave your site.
                      Don't lose potential business with a slow website.{" "}
                    </p>
                    <div className="call-to-action-link">
                      <p className="paragraph">
                        <HiOutlineArrowNarrowRight />
                        <Link to="/blog/website-performance-matters">
                          Read more about website performance
                        </Link>
                      </p>
                    </div>
                  </article>

                  <article>
                    <figure>
                      <Image
                        fixed={fixedOne}
                        fadeIn={false}
                        loading="eager"
                        alt={imageOneAlt}
                      />
                    </figure>
                  </article>
                </div>
              </div>
            </div>

            <div className="u-column-wrap u-left-text">
              <div className="u-column-wrap u-left-text u-padding">
                <div className="row">
                  <div className="col-2-of-2">
                    <figure>
                      <Image
                        fixed={fixedTwo}
                        fadeIn={false}
                        loading="eager"
                        alt={imageTwoAlt}
                      />
                    </figure>

                    <article className="services-page__article">
                      <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                        Get more customers
                      </h2>
                      <p className="paragraph">
                        I take website speed as a priority. Increase conversion
                        rates with a fast and modern website and get a better
                        ranking on SEO, with equals to more traffic and more
                        revenues.
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services-page__info section background u-padding">
            <div className="row u-no-margin-bottom">
              <div className="col-2-of-2">
                {services &&
                  services.map(service => {
                    const { name, info, tags } = service.data

                    return (
                      <article className="u-margin-bottom-big">
                        <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                          {name.text}
                        </h2>
                        <p className="paragraph">{info.text}</p>

                        <div className="u-tags">
                          {tags &&
                            tags.map((tag, index) => {
                              return (
                                <span className="u-tag is-light" key={index}>
                                  {tag.tag.text}
                                </span>
                              )
                            })}
                        </div>
                      </article>
                    )
                  })}
              </div>
            </div>
          </section>

          <CallToAction bigTitle="Ready to build something cool together?">
            <h2 className="heading-primary heading-primary--main u-margin-top-small u-no-margin-bottom">
              I offer affordable packages that fit any budget - book a call
              today!
            </h2>

            <button aria-label="button">
              <Link to="/contact" className="btn btn--primary u-margin-top">
                Book a call now
              </Link>
            </button>
            <p id="text">free consultation</p>
          </CallToAction>
        </section>
      </main>
    </Layout>
  )
}

export default ServicesPage
